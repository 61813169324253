export class User {
    id?:string;
    name: string;
    userName: string;
    email: string;
    startStopInstance?: boolean;
    viewReports?: boolean;
    createRule?: boolean;
    isGlobalAdmin: boolean;
    isLocalAdmin: boolean;
    password?: string;
    companyId?: number;
    permisions?: Permission[];
    instanceIDs?: string[];
    PasswordChangeRequired?:boolean;
    SendEmail?:boolean;
    enabled?:boolean;
    generatePassword?: boolean;
    passwordChangeRequired: boolean;
    sendEmail: boolean;
    selected: boolean;
}

export class Permission{
    nombre: string;
    userId: string;
    instanceId?: string;
    startStopInstance: boolean;
    viewReports: boolean;
    createRule: boolean;
}

export class UpdateUser{
    id: string;
    userName: string;
    email: string;
    isGlobalAdmin: boolean;
    isLocalAdmin: boolean;
    companyID: number;
    startStopInstance: boolean;
    viewReports: boolean;
    createRule: boolean;
    instanceIDs:string [];
    name: string;
 }